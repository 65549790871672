<template>
  <v-container v-if="dataStudio" fluid>
    <v-row justify="center">
      <v-col sm="11">
        <v-card :loading="loading">
          <v-card-text
            style="color: #FB8C00"
            :class="{ 'body-2': $vuetify.breakpoint.xs }"
            class="headline text-center"
          >
            Выберите любой из следующих планов, чтобы начать. <br />
            Некоторые продукты вы можете приобрести при помощи внутренней валюты (F).
            <dialog-cmp />
            Покупая продукт, вы соглашаетесь с <dialog-offer-agreement />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-container>
      <v-row justify="space-around">
        <template v-for="(item, index) in dataPriceStudio">
          <v-col v-if="switchPrise(item)" :key="index" cols="12" sm="6" md="3">
            <v-card
              class="d-block align-center justify-center"
              color="#7fc7e5"
              :min-height="$vuetify.breakpoint.xs ? '' : 450"
            >
              <v-card-text
                class="text-center headline font-weight-bold"
                :class="{ 'title font-weight-bold': $vuetify.breakpoint.xs }"
                style="color: white"
                v-text="dataStudio.studioName"
              />
              <v-card-text
                :class="{ 'body-2': $vuetify.breakpoint.xs }"
                class="headline text-center "
                v-text="item.status"
              />
              <v-card-text
                :class="{ 'body-2': $vuetify.breakpoint.xs }"
                class="headline text-center font-italic"
                v-text="item.anteaters ? 'Стоимость в муравьедах' : 'Стоимость'"
              />
              <v-card-text
                class="display-2 font-weight-bold text-center"
                :class="{ 'headline font-weight-bold': $vuetify.breakpoint.xs }"
                :style="`color: ${colorPrice === index ? 'orange' : 'white'}`"
                v-text="
                  item.price && item.anteaters
                    ? `${item.price}€ + ${item.anteaters}F`
                    : '' ||
                      (item.price
                        ? `${item.price}€`
                        : '' || item.anteaters
                        ? `${item.anteaters}F`
                        : '')
                "
              />
              <v-card-text>
                <promo-code
                  v-if="showPromoCode(item)"
                  @changePrices="changePrices($event, index)"
                />
              </v-card-text>

              <v-card-text
                v-if="user.id && (+user.anteaters || 0) < +item.anteaters"
                style="color: darkred; white-space: pre-line"
                class="text-center mt-n6"
                >У Вас не хватает муравьедов</v-card-text
              >
              <v-card-actions v-else class="mt-n6">
                <v-row justify="center">
                  <dialog-data-user
                    justify="center"
                    :data-price="getPrice(item)"
                    :data-studio="dataStudio"
                    :type-exercise="typeExercise"
                  />
                </v-row>
              </v-card-actions>
            </v-card>
            <v-card-text
              v-if="!user.id && $vuetify.breakpoint.xs"
              style="color: darkred; white-space: pre-line"
              class="text-center"
            >
              Чтобы совершить покупку, вам необходимо
              <router-link to="/login" class="cursorPointer"> зарегистрироваться.</router-link> При
              регистрации вы получаете в подарок 10F(муравьедов) <br />
              После оплаты ссылка на {{ typeExercise === 'studios' ? 'студию' : 'календарь' }} будет
              также доступна в вашем личном кабинете</v-card-text
            >
          </v-col>
        </template>
      </v-row>
    </v-container>
    <v-card-text
      v-if="!user.id && !$vuetify.breakpoint.xs"
      style="color: darkred; white-space: pre-line"
      class="text-center"
    >
      Чтобы совершить покупку, вам необходимо
      <router-link to="/login" class="cursorPointer"> зарегистрироваться.</router-link> При
      регистрации вы получаете в подарок 10F(муравьедов) <br />
      После оплаты ссылка на {{ typeExercise === 'studios' ? 'студию' : 'календарь' }} будет также
      доступна в вашем личном кабинете</v-card-text
    >
    <v-row justify="center">
      <v-col sm="11">
        <v-card>
          <v-card-text
            style="color: #FB8C00"
            :class="{ 'body-2': $vuetify.breakpoint.xs }"
            class="headline text-center"
          >
            Количество собранных муравьедов за полное прохождение:
            {{ dataStudio.numberAnteaters }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'StudioPrice',
  components: {
    DialogDataUser: () => import('../shared/dialog/DialogDataUser'),
    DialogCmp: () => import('../shared/dialog/DialogCmp'),
    DialogOfferAgreement: () => import('../shared/dialog/DialogOfferAgreement'),
    PromoCode: () => import('@/components/shared/PromoCode')
  },
  props: {
    titleStudios: {
      type: String,
      required: true
    },
    typeExercise: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dataPriceStudio: [],
      colorPrice: ''
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      loading: state => state.shared.loading,
      priceStudio: state => state.studios.priceStudios,
      promoCodes: state => state.shared.promoCodes
    }),
    dataStudio() {
      if (this.typeExercise === 'studios') {
        return this.$store.getters.dataStudio(this.titleStudios)
      } else {
        return this.$store.getters.dataCalendar(this.titleStudios)
      }
    },
    discount() {
      if (this.user.payments) {
        return this.dataPriceStudio.some(prise =>
          Object.keys(this.user.payments).includes(prise.titleDiscount)
        )
      } else {
        return false
      }
    }
  },
  watch: {
    titleStudios: {
      deep: true,
      immediate: true,
      handler() {
        this.$store
          .dispatch('getPriceStudios', {
            typeExercise: this.typeExercise,
            titleStudios: this.titleStudios
          })
          .then(() => (this.dataPriceStudio = this.priceStudio.map(price => ({ ...price }))))
      }
    }
  },
  created() {
    if (!this.promoCodes.length) {
      this.$store.dispatch('getPromoCodes')
    }
  },
  methods: {
    getPrice(dataPrice) {
      let typeExercise = dataPrice.typeGroup === 'group' ? 'studiosGroup' : this.typeExercise
      if (this.differenceAnteaters || !this.user.id) {
        return {
          price: '',
          anteaters: ''
        }
      } else {
        return {
          anteaters: dataPrice.anteaters || '',
          price: dataPrice.price || '',
          status: dataPrice.status,
          typeExercise,
          titleStudiosPrice: dataPrice.titleStudiosPrice || this.titleStudios,
          typeGroup: dataPrice.typeGroup,
          subscription: dataPrice.subscription || false,
          subscriptionIntervalCount: dataPrice.subscriptionIntervalCount || 1
        }
      }
    },
    switchPrise(item) {
      return (
        (!item.titleDiscount || this.discount) &&
        (!item.checkClub || (this.user.club && this.user.club.creationTime))
      )
    },
    showPromoCode(item) {
      return (
        this.promoCodes.some(
          el => el.productType.includes(this.typeExercise) || el.product.includes(this.titleStudios)
        ) &&
        !item.titleDiscount &&
        !item.anteaters &&
        !item.checkClub
      )
    },
    changePrices(discount, index) {
      if (discount !== 0) {
        this.dataPriceStudio[index].price = Math.round(
          +this.dataPriceStudio[index].price -
            (+this.dataPriceStudio[index].price * +discount) / 100
        )
        this.colorPrice = index
      } else {
        this.dataPriceStudio[index].price = this.priceStudio[index].price
        this.colorPrice = ''
      }
    }
  }
}
</script>

<style scoped />
